@use '@angular/material' as mat;
@include mat.core();

@import 'primeicons/primeicons';
@import 'primeflex/primeflex';

@import "primeng/resources/primeng";
@import "primeng/resources/themes/lara-light-blue/theme";

@import "themes/pocketlist-theme";

@import "../node_modules/quill/dist/quill.core.css";
@import "../node_modules/quill/dist/quill.snow.css";

// Define variables for breakpoints
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

// SCSS mixin for small screens (phones)
@mixin sm {
  @media (max-width: $sm) {
    @content;
  }
}

// SCSS mixin for medium screens (tablets)
@mixin md {
  @media (min-width: #{$sm + 1}) and (max-width: $md) {
    @content;
  }
}

// SCSS mixin for large screens (notebook monitors)
@mixin lg {
  @media (min-width: #{$md + 1}) and (max-width: $lg) {
    @content;
  }
}

// SCSS mixin for extra-large screens (desktop monitors)
@mixin xl {
  @media (min-width: #{$lg + 1}) and (max-width: $xl) {
    @content;
  }
}


html,
body {
  height: 100%;
  margin: 0;
  padding: 0;

}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.pointer {
  cursor: pointer;

  &:hover>.text-lg {
    text-decoration: underline;
  }
}

div,
p,
span,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
}

pre {
  white-space: pre-wrap;
  word-break: break-word;
  overflow-wrap: break-word;
}

.progress-fade-out {
  transition: opacity 1.00s ease-out;
  opacity: 0;
  z-index: -1;
}

.mx-auto {
  margin: 0 auto;
}

/* button spinner */
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

button.spinner:before {
  content: '';
  box-sizing: border-box;
  border-radius: 50%;
  border: 5px solid #ffffff;
  border-top-color: $primary;
  animation: spinner .8s linear infinite;
}

button.fade-in {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.5s ease-in, visibility 0.5s ease-in;
}

button.fade-out {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.5s ease-out, visibility 0.5s ease-out;
}

.glow-white {
  box-shadow: 0 0 25px white;
}

.nowrap {
  white-space: nowrap;
}

.actions {
  width: 0;
  white-space: nowrap;
}

.v-hidden {
  visibility: hidden;
}

p-table {

  th,
  td {
    align-content: flex-start;
  }

  th {
    font-weight: bold;
  }

  // Surpress margin on paragraphs
  td div p:first-child {
    margin-top: 0;
  }

  td div p:last-child {
    margin-bottom: 0;
  }

  p-button button {
    margin-top: -10.5px;
    margin-bottom: -10.5px;
  }
}

app-show-more {
    // Surpress margin on paragraphs
    p:first-child {
      margin-top: 0;
      margin-bottom: 0;
    }

    p-button button {
      margin-top: -15px;
      margin-bottom: -15px;
    }
}

p-autoComplete {
  p-button button {
    margin-top: -15px;
    margin-bottom: -15px;
    }
}

p-selectbutton.small-buttons .p-buttonset .p-button {
  font-size: 0.75rem;
  padding: 0.25rem 0.5rem;
}