@use '@angular/material' as mat;
@include mat.core();

// Available color palettes: https://material.io/design/color/

$pocketlist-primary: mat.define-palette(mat.$blue-palette);
$pocketlist-accent: mat.define-palette(mat.$yellow-palette);
$pocketlist-warn: mat.define-palette(mat.$red-palette);
$pocketlist-grey: mat.define-palette(mat.$grey-palette);

$pocketlist-theme: mat.define-light-theme((color: (primary: $pocketlist-primary,
                accent: $pocketlist-accent,
                warn: $pocketlist-warn,
            ),
            typography: mat.define-typography-config(),
            density: 0));

$primary: mat.get-color-from-palette($pocketlist-primary);
$accent: mat.get-color-from-palette($pocketlist-accent);
$warn: mat.get-color-from-palette($pocketlist-warn);
$grey: mat.get-color-from-palette($pocketlist-grey);
$white-when-bg-primary: #FFFFFF;

@include mat.core-theme($pocketlist-theme);
@include mat.button-theme($pocketlist-theme);
@include mat.all-component-themes($pocketlist-theme);

// mat.$yellow-palette
// mat.$orange-palette
// mat.$pink-palette